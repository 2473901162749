import React from 'react';
import * as JsSearch from 'js-search';
import {graphql, useStaticQuery} from 'gatsby';

const useNewsSearch = searchQuery => {
  const {strapi: {news}} = useStaticQuery(graphql`
    query {
      strapi {
        news: newsItems (sort: "created_at:desc") {
          ...NewsItem
          SEO {
            ...SEO
          }
        }
      }
    }
  `);
  const [search, setSearch] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    if (!news) return;
    // not yet supported from js search that is why the tags are updated on the model
    // https://github.com/bvaughn/js-search/pull/78
    const newsWithTags = news.map(n => ({
      ...n,
      tags: n.tags.map(t => t.tag).join(' ')
    }));
    const dataToSearch = new JsSearch.Search('slug');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('slug');
    dataToSearch.addIndex('title');
    // not yet supported from js search that is why the tags are updated on the model
    // https://github.com/bvaughn/js-search/pull/78
    dataToSearch.addIndex(['tags']);
    dataToSearch.addIndex(['intro', 'body', 'text']);
    dataToSearch.addIndex('slug');
    dataToSearch.addIndex(['SEO', 'title']);
    dataToSearch.addIndex(['SEO', 'keywords']);
    dataToSearch.addIndex(['SEO', 'description']);
    dataToSearch.addDocuments(newsWithTags);
    setSearch(dataToSearch);
  }, [news, searchQuery]);

  React.useEffect(() => {
    if (!search) return;
    const queryResult = searchQuery ? search.search(searchQuery) : news;
    setSearchResults(queryResult);
  }, [searchQuery, search]);
  return ({
    news: searchResults.map(s => ({
      title: s.SEO?.title,
      slug: `/news${s.slug}`,
      description: s.SEO?.description,
      category: 'news'
    }))
  });
};

export default useNewsSearch;
