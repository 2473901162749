import React from 'react';
import {Pagination as MUIPagination} from '@material-ui/lab';
import styled from 'styled-components';
import {colors, breakpoints} from '../../styles/theme';
import {getWindowDimensions} from '../../utils/dimensions';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;

const _Pagination = styled(MUIPagination)`
  &.root {
    color: red;
    & > ul {
      display: flex;
      justify-content: center;
      align-items: center;
      & > li {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: 2px;
        cursor: pointer;
        border: ${({active}) => active ? `1px solid ${colors.blue}` : 'none'};
        & > button {
          color: ${colors.blue};
          &.Mui-selected {
            color: white;
            background-color: ${colors.blue};
          }
        }
      }
    }
  }
`;

const Pagination = ({activePage, noPages, onPageChange}) => {
  const isMobile = getWindowDimensions().width < breakpoints.tabletPortrait;
  const isLargeDesktop = getWindowDimensions().width > breakpoints.desktopL;
  return (
    <Container>
      <_Pagination
        page={activePage}
        size={
          isMobile
            ? 'small'
            : isLargeDesktop
              ? 'large'
              : 'medium'
        }
        count={noPages}
        shape="rounded"
        defaultPage={1}
        siblingCount={1}
        onChange={(_, page) => onPageChange(page)}
        classes={{
          root: 'root',
        }}
      />
    </Container>
  );
};
export default Pagination;
