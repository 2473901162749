import React from 'react';
import * as JsSearch from 'js-search';
import {graphql, useStaticQuery} from 'gatsby';

const useSinglePageSearch = searchQuery => {
  const {pages} = useStaticQuery(graphql`
    query {
      pages: strapi {
        homePage {
          SEO {
            ...SEO
          }
        }
        newsOverview {
          SEO {
            ...SEO
          }
        }
        projectsOverview {
          SEO {
            ...SEO
          }
        }
        systemOverview {
          SEO {
            ...SEO
          }
        }
        storyOverview {
          SEO {
            ...SEO
          }
        }
        singlePages: pages {
          slug
          SEO {
            ...SEO
          }
        }
      }
    }
  `);
  const [search, setSearch] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    if (!pages) return;
    const _pages = [
      {
        ...pages.homePage,
        slug: '/'
      },
      {
        ...pages.newsOverview,
        slug: '/news'
      },
      {
        ...pages.projectsOverview,
        slug: '/projects'
      },
      {
        ...pages.systemOverview,
        slug: '/systems'
      },
      {
        ...pages.storyOverview,
        slug: '/stories'
      },
      ...pages.singlePages
    ];
    const dataToSearch = new JsSearch.Search('slug');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('slug');
    dataToSearch.addIndex('slug');
    dataToSearch.addIndex(['SEO', 'title']);
    dataToSearch.addIndex(['SEO', 'keywords']);
    dataToSearch.addIndex(['SEO', 'description']);
    dataToSearch.addDocuments(_pages);
    setSearch(dataToSearch);
  }, [pages, searchQuery]);

  React.useEffect(() => {
    if (!search) return;
    const _pages = [
      {
        ...pages.homePage,
        slug: '/'
      },
      {
        ...pages.newsOverview,
        slug: '/news'
      },
      {
        ...pages.projectsOverview,
        slug: '/projects'
      },
      {
        ...pages.systemOverview,
        slug: '/systems'
      },
      {
        ...pages.storyOverview,
        slug: '/stories'
      },
      ...pages.singlePages
    ];
    const queryResult = searchQuery ? search.search(searchQuery) : _pages;
    setSearchResults(queryResult);
  }, [searchQuery, search]);

  return ({
    pages: searchResults.map(p => ({
      title: p.SEO?.title,
      slug: p.slug,
      description: p.SEO?.description,
      category: 'pages'
    }))
  });
};

export default useSinglePageSearch;
