import React from 'react';
import * as JsSearch from 'js-search';
import {useStaticQuery, graphql} from 'gatsby';

const useServiceSearch = searchQuery => {
  const {strapi: {services}} = useStaticQuery(graphql`
    query {
      strapi {
        services {
          slug
          intro {
            ...Intro
          }
          name
          header {
            text
          }
          SEO {
            ...SEO
          }
        }
      }
    }
  `);
  const [search, setSearch] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    if (!services) return;
    const dataToSearch = new JsSearch.Search('slug');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('slug');
    dataToSearch.addIndex('name');
    dataToSearch.addIndex(['SEO', 'title']);
    dataToSearch.addIndex(['SEO', 'keywords']);
    dataToSearch.addIndex(['SEO', 'description']);
    dataToSearch.addIndex(['header', 'text']);
    dataToSearch.addIndex(['intro', 'text', 'body']);
    dataToSearch.addDocuments(services);
    setSearch(dataToSearch);
  }, [services, searchQuery]);
  React.useEffect(() => {
    if (!search) return;
    const queryResult = searchQuery ? search.search(searchQuery) : services;
    setSearchResults(queryResult);
  }, [searchQuery, search]);
  return ({
    services: searchResults.map(s => ({
      title: s.SEO?.title,
      slug: s.slug,
      description: s.SEO?.description,
      category: 'services'
    }))
  });
};

export default useServiceSearch;
