import {graphql, useStaticQuery} from 'gatsby';

const useMarketsData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        markets (sort: "displayOrder:asc") {
          SEO {
            ...SEO
          }
          slug
          displayName
          header {
            ...RegularHeader
          }
          intro {
            ...Intro
          }
          blocks {
            __typename
            ...MediaBlock
            ...PlainText
            ...Testimonial
            ...USPBlock
            ...MediaWithText
            ...Carousel
            ...Share
            ...ContactPersonBlock
            ...Download
          }
        }
      }
    }
  `);
  return ({
    ...data.strapi,
  });
};

export default useMarketsData;
