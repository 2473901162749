import React from 'react';
import * as JsSearch from 'js-search';
import useMarketsData from '../../queries/useMarketsData';

const useMarketSearch = searchQuery => {
  const {markets} = useMarketsData();
  const [search, setSearch] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    if (!markets) return;
    const dataToSearch = new JsSearch.Search('slug');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('slug');
    dataToSearch.addIndex('displayName');
    dataToSearch.addIndex(['SEO', 'title']);
    dataToSearch.addIndex(['SEO', 'keywords']);
    dataToSearch.addIndex(['SEO', 'description']);
    dataToSearch.addIndex(['header', 'text']);
    dataToSearch.addIndex(['intro', 'text', 'body']);
    dataToSearch.addDocuments(markets);
    setSearch(dataToSearch);
  }, [markets, searchQuery]);
  React.useEffect(() => {
    if (!search) return;
    const queryResult = searchQuery ? search.search(searchQuery) : markets;
    setSearchResults(queryResult);
  }, [searchQuery, search]);
  return ({
    markets: searchResults.map(s => ({
      title: s.SEO?.title,
      slug: s.slug,
      description: s.SEO?.description,
      category: 'markets'
    }))
  });
};

export default useMarketSearch;
