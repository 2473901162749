import React from 'react';
import * as JsSearch from 'js-search';
import {graphql, useStaticQuery} from 'gatsby';

const useSystemSearch = searchQuery => {
  const {strapi: {systems}} = useStaticQuery(graphql`
    query {
      strapi {
        systems {
          ...System
          SEO {
            ...SEO
          }
        }
      }
    }
  `);
  const [search, setSearch] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    if (!systems) return;
    const dataToSearch = new JsSearch.Search('slug');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('slug');
    dataToSearch.addIndex(['SEO', 'title']);
    dataToSearch.addIndex(['SEO', 'description']);
    dataToSearch.addIndex(['SEO', 'keywords']);
    dataToSearch.addIndex('shortDescription');
    dataToSearch.addIndex('name');
    dataToSearch.addIndex('slug');
    dataToSearch.addIndex('transferType');
    dataToSearch.addIndex(['intro', 'text', 'title']);
    dataToSearch.addIndex(['intro', 'text', 'body']);
    dataToSearch.addIndex(['info', 'description']);
    dataToSearch.addIndex(['info', 'specifications', 'title']);
    dataToSearch.addDocuments(systems);
    setSearch(dataToSearch);
  }, [systems, searchQuery]);

  React.useEffect(() => {
    if (!search) return;
    const queryResult = searchQuery ? search.search(searchQuery) : systems;
    setSearchResults(queryResult);
  }, [searchQuery, search]);
  return ({
    systems: searchResults.map(s => ({
      title: s.SEO?.title,
      slug: `/systems${s.slug}`,
      description: s.SEO?.description,
      category: 'systems'
    }))
  });
};

export default useSystemSearch;
