import React from 'react';
import * as JsSearch from 'js-search';
import {graphql, useStaticQuery} from 'gatsby';

const useProjectSearch = searchQuery => {
  const {strapi: {projects}} = useStaticQuery(graphql`
    query {
      strapi {
        projects {
          ...Project
          SEO {
            ...SEO
          }
        }
      }
    }
  `);
  const [search, setSearch] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    if (!projects) return;
    const dataToSearch = new JsSearch.Search('slug');
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('slug');
    dataToSearch.addIndex('title');
    dataToSearch.addIndex('achievement');
    dataToSearch.addIndex('slug');
    dataToSearch.addIndex(['SEO', 'title']);
    dataToSearch.addIndex(['SEO', 'keywords']);
    dataToSearch.addIndex(['SEO', 'description']);
    dataToSearch.addDocuments(projects);
    setSearch(dataToSearch);
  }, [projects, searchQuery]);

  React.useEffect(() => {
    if (!search) return;
    const queryResult = searchQuery ? search.search(searchQuery) : projects;
    setSearchResults(queryResult);
  }, [searchQuery, search]);
  return ({
    projects: searchResults.map(s => ({
      title: s.SEO?.title,
      slug: `/projects${s.slug}`,
      description: s.SEO?.description,
      category: 'projects'
    }))
  });
};

export default useProjectSearch;
