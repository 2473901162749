import React from 'react';
import styled from 'styled-components';
import {colors} from '../../styles/theme';
import {H5, Body, Subtitle, Link} from '../atoms';
import TextTruncate from 'react-text-truncate';

const SearchResultContainer = styled(Link)`
  text-decoration: none;
  margin: 24px 0;
  display: inline-block;
  ${H5} {
    color: ${colors.blue};
    &:hover {
      color: ${colors.lightBlue};
    }
  }
  ${Subtitle} {
    color: ${colors.xanadu};
  }
  `;

const Slug = styled(Body)`
  margin: 4px 0;
  color: ${colors.grey};
`;

const Tag = styled.p`
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.lightBlue};
  text-transform: uppercase;
  font-weight: bold;
`;

const SearchResult = ({result}) => (
  <SearchResultContainer to={result.slug}>
    <Tag>{result.category}</Tag>
    <H5>{result.title}</H5>
    <Slug>{result.slug}</Slug>
    <TextTruncate
      line={2}
      element={Subtitle}
      truncateText="…"
      text={result.description}
    />
  </SearchResultContainer>
);

export default SearchResult;
