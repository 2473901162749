import React from 'react';
import styled from 'styled-components';
import Pagination from '../components/organisms/Pagination';
import {NavigationContext} from '../services/navigation';
import {PageContent} from '../components/organisms/Layout';
import {useSystemSearch,
  useMarketSearch,
  useProjectSearch,
  useServiceSearch,
  useSinglePageSearch,
  useStorySearch,
  useNewsSearch
} from '../hooks/search';
import {navigate} from 'gatsby';
import {GridComponentContainer, H1, Body, TextContainer} from '../components/atoms';
import {colors, devices} from '../styles/theme';
import SearchIcon from '../images/svgsComponents/Search.svg';
import {hex2rgb} from '../utils/hex2rgb';
import SearchResult from '../components/organisms/SearchResult';
import document from '../utils/document';

const Container = styled(TextContainer)`
  ${H1} {
    color: ${colors.blue};
    margin-bottom: 16px;
  }
  ${Body} {
    font-weight: bold;
  }
`;

const FormContainer = styled.div`
  margin: 0;
  border: 1px solid ${colors.blue};
  border-radius: 2px;
  margin: 32px 0;
`;

const SearchInputContainer = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  height: 40px;
  color: ${colors.xanadu};
  padding: 4px 12px 0;
  font-size: 16px;
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  @media ${devices.tabletLandscape} {
    padding-top: 6px;
    font-size: 18px;
  }
  @media ${devices.desktopL} {
    padding-top: 8px;
    font-size: 20px;
  }
`;

const SearchLogoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  & > svg {
    height: 28px;
    width: 28px;
    & > path {
      fill: ${colors.blue};
      transition: fill 600ms;
    }
  }
  @media ${devices.tabletLandscape} {
    &:hover {
      & > svg > path {
        fill: ${hex2rgb(colors.lightBlue)};
      }
    }
  }
`;

const RESULTS_PER_PAGE = 10;

const ZoekenPage = () => {
  const {setLocation} = React.useContext(NavigationContext);
  const params = new URLSearchParams(document?.location?.search?.substring(1));
  const query = params.get('q');
  const _pageNumber = Number(params.get('p')) || 1;
  const [searchQuery, setSearchQuery] = React.useState('');
  const [pageNumber, setPageNumber] = React.useState(_pageNumber);
  const {markets} = useMarketSearch(query);
  const {news} = useNewsSearch(query);
  const {pages} = useSinglePageSearch(query);
  const {projects} = useProjectSearch(query);
  const {services} = useServiceSearch(query);
  const {systems} = useSystemSearch(query);
  const {stories} = useStorySearch(query);
  const searchResults = [
    ...markets,
    ...news,
    ...pages,
    ...projects,
    ...services,
    ...systems,
    ...stories
  ];
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  const handleSubmit = e => {
    e.preventDefault();
    setPageNumber(1);
    params.delete('q');
    params.delete('p');
    searchQuery && params.set('q', searchQuery);
    navigate(`/zoeken?${params.toString()}`);
    setSearchQuery('');
  };
  const pageResults = searchResults.slice(
    (pageNumber - 1) * RESULTS_PER_PAGE,
    pageNumber * RESULTS_PER_PAGE
  );
  const noPages = Math.ceil(searchResults.length / RESULTS_PER_PAGE) || 1;
  return (
    <PageContent>
      <GridComponentContainer fromColumn={3} widthInColumns={4}>
        <Container>
          <H1>Search results</H1>
          {query && (
            <Body style={{color: colors.blue}}>
              {`
              ${searchResults.length} result${searchResults.length !== 1 ? 's' : ''}
              for "${decodeURIComponent(query)}"`}
            </Body>
          )}
          <FormContainer>
            <form onSubmit={handleSubmit} style={{margin: 0}}>
              <SearchInputContainer>
                <SearchInput
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  placeholder="New search"
                />
                <SearchLogoContainer onClick={handleSubmit}>
                  <SearchIcon />
                </SearchLogoContainer>
              </SearchInputContainer>
            </form>
          </FormContainer>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {pageResults.map(res => (
              <SearchResult
                key={res.slug}
                result={res}
              />
            ))}
          </div>
          {noPages > 1 && <Pagination
            activePage={pageNumber}
            noPages={noPages}
            onPageChange={pageNumber => {
              query && params.set('q', query);
              params.set('p', pageNumber);
              navigate(`/zoeken?${params.toString()}`);
              setPageNumber(pageNumber);
            }}
          />}
        </Container>
      </GridComponentContainer>
    </PageContent>
  );
};

export default ZoekenPage;
